import { createContext, useContext, ReactNode, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

interface NavigationContextType {
  hasInternalHistory: boolean;
}

const NavigationContext = createContext<NavigationContextType | undefined>(
  undefined,
);

const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const hasInternalHistory = useRef(false);

  useEffect(() => {
    // Set up listener for internal navigation
    router.beforePopState(() => {
      hasInternalHistory.current = true;
      return true;
    });

    // Track internal navigation by storing previous path
    const handleRouteChange = () => {
      hasInternalHistory.current = true;
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.beforePopState(() => true); // Reset to default allowing behavior
    };
  }, [router]);

  return (
    <NavigationContext.Provider
      value={{ hasInternalHistory: hasInternalHistory.current }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
