import { ApolloClient, from, InMemoryCache, split } from '@apollo/client';
import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities';
import { authLink } from './authLink';
import { errorLink } from './errorLink';
import { httpLink } from './httpLink';
import { logLink } from './logLink';
import { tokenRefreshLink } from './tokenRefreshLink';
import { sentryLink } from './sentryLink';
import { traceIdLink } from './traceIdLink';
import { wsLink } from './wsLink';
import { operationNameLink } from './operationNameLink';
import { API_URI } from '../endpoints';

console.log(`API_ENDPOINT=${API_URI}`);

const httpLinkChain = from([
  tokenRefreshLink,
  authLink,
  sentryLink,
  errorLink,
  logLink,
  traceIdLink,
  operationNameLink,
  httpLink,
]);

const splitLink = split(
  ({ query }) => {
    // i.e. use wsLink if subscription, otherwise httpLinkChain
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLinkChain,
);

const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: {
      User: ['SystemUser', 'EnvironmentMember', 'WorkspaceMember'],
    },
    typePolicies: {
      Environment: {
        fields: {
          embedAuthorizations: relayStylePagination(),
          featureTemplates: relayStylePagination(),
          members: relayStylePagination(),
          workspaces: relayStylePagination(),
          chatThreads: relayStylePagination(),
          chatResponses: relayStylePagination(),
        },
      },
      Workspace: {
        fields: {
          chatResponses: relayStylePagination(),
          chatThreads: relayStylePagination(),
          components: relayStylePagination(),
          features: relayStylePagination(),
          members: relayStylePagination(),
          featureActionLogEntries: relayStylePagination(),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
  link: splitLink,
});

export default client;
