import { useMemo, useState } from 'react';

import {
  FeedbackRating,
  useEnvironmentChatLogResponsesLazyQuery,
  useEnvironmentChatLogThreadsLazyQuery,
  PageInfo as ServerPageInfo,
} from '../generated/types';
import { DATE_FILTERS } from '../components/ChatLogsTable/DateRangeFilterMenuButton';

type BaseRow = {
  id: string;
  workspaceId: string;
  workspaceName: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
};

type ChatThreadRow = BaseRow & {
  threadId: string;
  threadStartTime: Date;
  threadName: string;
};

type ChatMessageRow = BaseRow & {
  responseId: string;
  time: Date;
  completed: boolean;
  prompt: string | undefined;
  rating: FeedbackRating | null | undefined;
};

const PAGE_SIZE_OPTIONS = [25, 50, 100];
const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];

// User(or Dev) changes this
export type PaginationModel = {
  page: number; // human readable page number. starts at 1
  pageSize: number; // number of items per page
  pageSizeOptions: number[];
};

// Server returns this
export type PageInfo = ServerPageInfo & {
  totalPages: number; // total number of pages
  totalItems: number; // total number of items
};

const defaultPageInfo: PageInfo = {
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

const defaultPaginationModel: PaginationModel = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  pageSizeOptions: PAGE_SIZE_OPTIONS,
};

export const useChatLogs = (variant: 'threads' | 'messages') => {
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(
    defaultPaginationModel,
  );

  const [selectedDateFilter, setSelectedDateFilter] = useState<{
    label: string;
    value: number;
  }>(DATE_FILTERS[0]);

  const handleDateFilterChange = (dateFilter: {
    label: string;
    value: number;
  }) => {
    setSelectedDateFilter(dateFilter);
    setPaginationModel({
      ...paginationModel,
      page: 1,
    });
  };

  const [
    getEnvironmentChatLogThreads,
    {
      data: environmentChatLogThreadsData,
      loading: environmentChatLogThreadsLoading,
    },
  ] = useEnvironmentChatLogThreadsLazyQuery();

  const [
    getEnvironmentChatLogResponses,
    {
      data: environmentChatLogResponsesData,
      loading: environmentChatLogResponsesLoading,
    },
  ] = useEnvironmentChatLogResponsesLazyQuery();

  const getLogs = useMemo(
    () =>
      variant === 'threads'
        ? getEnvironmentChatLogThreads
        : getEnvironmentChatLogResponses,
    [getEnvironmentChatLogResponses, getEnvironmentChatLogThreads, variant],
  );

  const pageInfo = useMemo<PageInfo>(() => {
    if (variant === 'threads') {
      if (environmentChatLogThreadsData?.node?.__typename === 'Environment') {
        return {
          ...environmentChatLogThreadsData.node.chatThreads.pageInfo,
          totalItems: environmentChatLogThreadsData.node.chatThreads.totalCount,
          totalPages: Math.ceil(
            environmentChatLogThreadsData.node.chatThreads.totalCount /
              paginationModel.pageSize,
          ),
        };
      }
    } else if (variant === 'messages') {
      if (environmentChatLogResponsesData?.node?.__typename === 'Environment') {
        return {
          ...environmentChatLogResponsesData.node.chatResponses.pageInfo,
          totalItems:
            environmentChatLogResponsesData.node.chatResponses.totalCount,
          totalPages: Math.ceil(
            environmentChatLogResponsesData.node.chatResponses.totalCount /
              paginationModel.pageSize,
          ),
        };
      }
    }
    return defaultPageInfo;
  }, [
    variant,
    environmentChatLogThreadsData,
    paginationModel.pageSize,
    environmentChatLogResponsesData,
  ]);

  const rows: ChatThreadRow[] | ChatMessageRow[] | null = useMemo(() => {
    if (
      variant === 'threads' &&
      environmentChatLogThreadsData?.node?.__typename === 'Environment'
    ) {
      return environmentChatLogThreadsData.node.chatThreads.edges.map(
        (edge) => {
          const thread = edge.node;
          return {
            id: thread.id,
            threadId: thread.id,
            threadName: thread.title,
            threadStartTime: thread.createdAt,
            workspaceId: thread.workspace.id,
            workspaceName: thread.workspace.name,
            userFirstName: thread.user.firstname,
            userLastName: thread.user.lastname,
            userEmail: thread.user.email,
          };
        },
      );
    } else if (
      variant === 'messages' &&
      environmentChatLogResponsesData?.node?.__typename === 'Environment'
    ) {
      return environmentChatLogResponsesData.node.chatResponses.edges.map(
        (edge) => {
          const response = edge.node;
          return {
            id: response.id,
            // workspace
            workspaceId: response.chatThread.workspace.id,
            workspaceName: response.chatThread.workspace.name,
            // thread
            threadId: response.chatThreadId,
            // response
            responseId: response.id,
            // user
            userFirstName: response.chatThread.user.firstname,
            userLastName: response.chatThread.user.lastname,
            userEmail: response.chatThread.user.email,
            // other
            time: response.createdAt,
            completed: response.completed,
            prompt: response.userPrompt,
            rating: response.rating,
          };
        },
      );
    }
    return null;
  }, [variant, environmentChatLogThreadsData, environmentChatLogResponsesData]);

  return {
    getLogs,
    environmentChatLogThreadsLoading,
    environmentChatLogResponsesLoading,
    rows,
    pageInfo,
    paginationModel,
    setPaginationModel,
    // date filter
    selectedDateFilter,
    handleDateFilterChange,
  };
};
