import { useState, useCallback } from 'react';
import { Popper, Card, MenuItem, ClickAwayListener } from '@mui/material';
import Button from '../shared/Button';
import Icon from '../shared/Icon';

export const DATE_FILTERS = [
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 90 days', value: 90 },
  { label: 'Last 365 days', value: 365 },
];

const DateRangeFilterMenuButton = ({
  selectedDateFilter,
  onDateFilterChange,
}: {
  selectedDateFilter: { label: string; value: number };
  onDateFilterChange: (dateFilter: { label: string; value: number }) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDateFilterChange = (dateFilter: {
    label: string;
    value: number;
  }) => {
    onDateFilterChange(dateFilter);
    closeDateRangeFilter();
  };

  const openDateRangeFilter = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const closeDateRangeFilter = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl]);

  return (
    <>
      <Button
        size='small'
        variant='outlined'
        startIcon={<Icon name='calendar' />}
        endIcon={<Icon name='carat-down' />}
        onClick={openDateRangeFilter}
        aria-haspopup='true'
        aria-expanded={Boolean(anchorEl)}
        aria-label='Select date range filter'
      >
        {selectedDateFilter.label}
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement='bottom-start'
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={closeDateRangeFilter}>
          <Card
            sx={{
              width: '320px',
              borderRadius: 2,
              boxShadow: (theme) => theme.shadows[3],
            }}
          >
            {DATE_FILTERS.map((dateFilter) => (
              <MenuItem
                key={dateFilter.value}
                onClick={() => handleDateFilterChange(dateFilter)}
              >
                {dateFilter.label}
              </MenuItem>
            ))}
          </Card>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default DateRangeFilterMenuButton;
